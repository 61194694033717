<template>
  <div class="full relative ph_5">
    <!-- 筛选 -->
    <div class="w_50_p flex_row_end absolute top_0 right_30 z_999"> 
      <div class="w_25 h_25">
        <img class="full cursor" src="~@/assets/images/chart-box/filter.png" alt="" @click="showFilterBox = true">
      </div>
    </div>

    <div 
      class="w_full flex_row_around absolute top_6_p left_center z_999 x_scroll ph_10 color_ccc"
      @wheel="wheelScroll"
    >
      <div 
        class="item_name hover_main_color" 
        :class="{ 'jian_bian_text_1 border_b_3_s_5e93d4': animalName == animal.name }" 
        v-for="(animal, index) in chartData.animalList" :key="index"
        @click="selectAnimal(animal)"
      > {{ animal.name }}
      </div>
    </div>

    <echart title="牲畜普查统计" chartType="环状图" :chartData="chartData" />

    <!-- 筛选区 -->
    <div 
      class="full absolute top_0 right_0" 
      v-show="showFilterBox"
      @click="showFilterBox = false"
    >
      <div class="w_40_p h_full absolute top_0 right_0 p_10 bgc_001531 z_999">
        <div class="w_full flex_row_between">
          <div class="w_85_p">
            <el-select 
              class="w_full_i" 
              v-model="panDianId" 
              size="mini" 
              placeholder="盘点工作" 
              clearable filterable
            >
              <el-option
                v-for="(item, index) in panDianList" :key="index"
                :label="item.name"
                :value="item.id"
                @click.native="selectPanDian(item.id)"
              >
              </el-option>
            </el-select>
          </div>

          <div class="w_15_p relative color_fff fw_bold cursor" @click="showFilterBox = false">
            <div class="w_20 h_20 absolute top_0 right_0">
              <img class="full cursor" src="~@/assets/images/chart-box/cancel.png" alt="">
            </div>
          </div>
        </div>
        
      </div>
    </div>
  </div>
</template>

<script>
  import defMixin from '@/mixins/def';
  import componentsMixin from '@/mixins/components.js'
  import { getJobList2 } from '@/api/fang-yi/fang-yi.js'

  export default {
    name: 'CattleCategoryChart',
    mixins: [defMixin, componentsMixin],
    components: {  },
    props: {
      chartData: {},
    },
    data() {
      return {
        showFilterBox: false,
        activeIndex: 0,
        animalName: '牛',

        panDianId: null,
        panDianList: [],

        chart_data: {
          unit: '头，只，羽',
          data: []
        },
      };
    },
    watch: {

    },
    created() {

    },
    mounted() {
      this.getPanDianList()
    },
    methods: {
      // 获取 盘点 列表
      async getPanDianList() {
        let res = await getJobList2()
        try {
          if (res.code == 1000) {
            let panDianList = res.data.filter(item => item.isType == 0)
            this.panDianList = panDianList

            for (let i in panDianList) {
              if (panDianList[i].active == 1) {
                this.panDianId = panDianList[i].id
                this.$emit('selectPanDian', this.panDianId)
              }
            }
          }
        } catch(err) {
          console.log(err)
        }
      },

      selectPanDian(panDianId) {
        this.$emit('selectPanDian', panDianId)
      },

      // 点击 牲畜 名称
      selectAnimal(animal) {
        this.animalName = animal.name
        this.$emit('selectAnimalCategoryName', animal.id)
      },
    }
  }
</script>