<template>
  <div class="full relative">
    <!-- 筛选 -->
    <!-- <div class="w_50_p flex_row_end absolute top_0 right_30" > 
      <div class="w_25 h_25">
        <img class="full cursor" src="~@/assets/images/chart-box/filter.png" alt="" @click="showFilterBox = true">
      </div>
    </div> -->

    <echart 
      title="动物免疫统计（免疫台账）" 
      chartType="双柱图" 
      :chartData="chartData" 
      :path="path" 
      @emitChartParams="handleClickMianYi" 
    />

    <!-- 筛选区 -->
    <div 
      class="full absolute top_0 right_0" 
      v-show="showFilterBox"
      @click="showFilterBox = false"
    >
      <div class="w_40_p h_full absolute top_0 right_0 p_10 bgc_001531 z_999">
        <div class="w_full flex_row_between">
          <div class="w_85_p h_full">
            <el-select 
              class="w_full_i" 
              v-model="jobId" 
              size="mini" 
              placeholder="防疫工作" 
              clearable filterable
            >
              <el-option
                v-for="(item, index) in jobList" :key="index"
                :label="item.name"
                :value="item.id"
                @click.native="selectJob(item.id)"
              >
              </el-option>
            </el-select>
          </div>

          <div class="w_15_p relative color_fff fw_bold cursor" @click="showFilterBox = false">
            <div class="w_20 h_20 absolute top_0 right_0">
              <img class="full cursor" src="~@/assets/images/chart-box/cancel.png" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import defMixin from '@/mixins/def';
  import componentsMixin from '@/mixins/components.js'
  import { getJobList } from '@/api/fang-yi/fang-yi.js'

  export default {
    name: 'AnimalCunLanFangYi',
    mixins: [defMixin, componentsMixin],
    props: {
      chartData: {},
      path: ''
    },
    data() {
      return {
        jobId: null,
        jobList: [],

        showFilterBox: false,
      };
    },
    watch: {
      villageId(newVal) {
        if (newVal == '150423203201') {
          this.chartData = {
            unit: '',
            x: ['牛', '羊', '猪', '禽', '犬', '马', '驴', '骆驼'].reverse(),
            data1: {
              name: '存栏数',
              data: [153, 745, 23, 131, 21, 24, 13, 30].reverse()
            },
            data2: {
              name: '防疫数',
              data: [231, 1056, 54, 235, 34, 34, 13, 54].reverse()
            },
            animalList: [],
          }

          this.chartData = {...this.chartData}
        }
      }
    },
    created() {

    },
    mounted() {
      // let jobId = parseInt(sessionStorage.getItem('job').jobId)
      // this.jobId = jobId
      // this.$emit('selectJob', jobId)

      // this.jobList = JSON.parse(sessionStorage.getItem('jobList'))

      // this.getJobList()
    },
    methods: {
      // 获取 防疫工作 列表
      async getJobList() {
        let res = await getJobList()
        try {
          if (res.code == 1000) {
            this.jobList = res.data.filter(item => item.isType == 1)

            let jobId = null
            
            for (let i in res.data) {
              if (res.data[i].active == 1) {
                jobId = res.data[i].id
                this.jobId = jobId
              }
            }
            this.$emit('selectJob', jobId)
          }
        } catch(err) {
          console.log(err)
        }
      },

      // 选择工作任务
      selectJob(jobId) {
        this.showFilterBox = false
        // 向 父组件 发送
        this.$emit('selectJob', jobId)
      },

      // 监听点击 双柱图 防疫 线条
      handleClickMianYi(val) {
        let animalId = val.id

        this.animalId = animalId

        // 向 父组件发射 事件 ： 获取 免疫次数
        this.$emit('handleClickFangYiCount', animalId)
      },

      // 对象数组 去重
      formatList(tempArr) {
        let arr = tempArr.reverse()

        let result = [];
        let obj = {};
        for (let i = 0; i < arr.length; i++) {
          if (!obj[arr[i].id]) {
            result.push(arr[i]);
            obj[arr[i].id] = true;
          };
        };

        return result;
      },

      // 格式化 牲畜 顺序 
      formatAnimalList(arr) {
        let sortdict = {
          "牛": 1,
          "羊": 2,
          "猪": 3,
          "禽": 4,
          "犬": 5,
          "马": 6,
          "驴": 7,
          "骆驼": 8
        }
        return arr.sort((a, b) => sortdict[a.animalName] - sortdict[b.animalName])
      }
    }
  }
</script>
