import MianYiYiMiaoChart from '@/components/data-charts/mianYiYiMiao.vue';
import CunLanMianYi from '@/components/data-charts/cunLanMianYi.vue';

import FangYiYuanMianYiYiMiaoChart from '@/components/data-charts/fangYiYuanMianYiYiMiao.vue';
import MuHuMianYiYiMiaoChart from '@/components/data-charts/muHuMianYiYiMiao.vue';

import YiMiaoChart from '@/components/data-charts/yiMiao.vue';

import CattleCategoryChart from '@/components/data-charts/cattleCategory.vue';

export default {
  components: {
    MianYiYiMiaoChart,
    CunLanMianYi,

    FangYiYuanMianYiYiMiaoChart,
    MuHuMianYiYiMiaoChart,

    YiMiaoChart,

    CattleCategoryChart
  }
}