<template>
  <div class="full flex_column_between">
    <!-- 存栏防疫统计 -->
    <div class="w_full h_49_p p_10 bgs_full chart_box_bgi_1 relative">
      <echart 
        class="full"
        title="防疫统计" 
        chartType="柱状图3" 
        :chartData="chart_data_1" 
        @emitChartParams="handleClickFangYi"
      />
    </div>
    
    <!-- 免疫统计 -->
    <div class="w_full h_49_p p_10 bgs_full chart_box_bgi_1 relative">
      <div 
        class="w_full flex_row_around color_ccc absolute top_8_p left_center z_999 x_scroll ph_10" 
        ref="tabName"
        @wheel="wheelScroll"
      >
        <div 
          class="item_name hover_main_color" 
          :class="{'jian_bian_text_1 fw_bold border_b_3_s_5e93d4': active_animal_id == item.animalId}" 
          v-for="(item, index) in chart_data_1.animalList" :key="index"
          v-show="item.animalName !== '山羊'"
          @click="selectAnimal(item.animalId)"
        >
          {{ item.animalName }} 
        </div>
      </div>

      <echart 
        title="免疫统计" 
        chartType="环状图"
        :chartData="chart_data_2" 
        @emitChartParams="handleClickYiMiao" 
      />
    </div>
  </div>
</template>

<script>
  import defMixin from '@/mixins/def';
  import componentsMixin from '@/mixins/components.js'
  import echartMixin from '@/mixins/echart.js'

  import { getRecordsYiStatisticsByPersonId, getRecordsYiStatisticsByAnimalId } from '@/api/fang-yi/fang-yi-yuan.js'

  export default {
    name: 'FangYiYuanMianYiYiMiaoChart',
    mixins: [defMixin, componentsMixin, echartMixin],
    components: {  },
    props: {
      // 防疫员 id
      antiepidemicPersonId: '',
      jobId: '',
      path: ''
    },
    data() {
      return {
        showFilterBox: false,
        activeIndex: 0,
        fangYiYuanFangYiList: [],

        // jobId: null,
        active_animal_id: null,

        active_job_name: null,
        chart_1_yimiao_id: null,
        chart_1_activeSeason: '',

        chart_data_1: {
          unit: '毫升',
          x: [],
          y: [],
          animalList: []
        },

        chart_data_2: {
          unit: '毫升',
          isShowUnit: true,
          data: []
        },

      };
    },
    watch: {
      async jobId(val) {
        await this.getRecordsYiStatisticsByPersonId(this.antiepidemicPersonId)

        await this.getRecordsYiStatisticsByAnimalId()
      }
    },
    created() {

    },
    async mounted() {
      // 获取 防疫员 的防疫列表
      await getRecordsYiStatisticsByPersonId(this.antiepidemicPersonId)
    },
    methods: {
      // 获取 防疫员 的防疫统计
      async getRecordsYiStatisticsByPersonId(id) {
        let res = await getRecordsYiStatisticsByPersonId(id)
        try {
          if (res.code == 1000) {
            let dataList = []
            
            for (let i in res.data) {
              if(res.data[i].jobId == this.jobId) {
                dataList = res.data[i]
              }
            }

            let animal_data = dataList.animalList.filter(item => item.animalName !== '山羊')
            
            this.chart_data_1.animalList = animal_data
            // this.active_animal_id = animal_data[0].animalId

            let animal_list = this.formatAnimalList(animal_data)

            let x = []
            let y = []
            for (let i in animal_list) {
              x.push(animal_list[i].animalName)
              y.push(animal_list[i].totalCount)
            }

            this.chart_data_1 = {
              unit: '头、只、羽',
              x: x.reverse(),
              y: y.reverse(),
              animalList: animal_data
            }

            this.chart_data_1 = {...this.chart_data_1}
          }
        } catch (err) {
          this.$message.error(err)
        }
      },


      // 点击 防疫柱状图
      handleClickFangYi(val) {
        this.fangYiYuanFangYiList[this.activeIndex].animalList.forEach(item => {
          if (item.animalName == val) {
            this.active_animal_id = item.animalId
          }
        })

        this.getRecordsYiStatisticsByAnimalId()

        this.chart_data_1 = {...this.chart_data_1}
      },

      // 获取 动物 的疫苗使用量
      async getRecordsYiStatisticsByAnimalId() {
        let params = {
          antiepidemicPersonId: this.antiepidemicPersonId,
          jobId: this.jobId,
          animalId: this.active_animal_id
        }

        this.chart_data_2.data = []
        
        let res = await getRecordsYiStatisticsByAnimalId(params)
        try {
          if (res.code == 1000) {
            for (let i in res.data) {
              let item = { name: res.data[i].vaccinumName, value: res.data[i].totalCount, unit: '毫升' }
              this.chart_data_2.data.push(item)
            }

            this.chart_data_2 = {...this.chart_data_2}
          }
        } catch (err) {
          console.log(err)
        }
      },

      // 选择 动物
      selectAnimal(val) {
        this.active_animal_id = val

        this.getRecordsYiStatisticsByAnimalId()

        this.chart_data_2 = {...this.chart_data_2}
      },

      // 格式化 牲畜 顺序 
      formatAnimalList(arr) {
        let sortdict = {
          "牛": 1,
          "羊": 2,
          "猪": 3,
          "禽": 4,
          "犬": 5,
          "马": 6,
          "驴": 7,
          "骆驼": 8
          }

        return arr.sort((a, b) => sortdict[a.animalName] - sortdict[b.animalName])
      }
    }
  }
</script>