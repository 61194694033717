<template>
  <div class="full flex_column_between">
    <!-- 存栏防疫统计 -->
    <div class="w_full h_49_p p_10 bgs_full chart_box_bgi_1 relative">
      <animal-cun-lan-fang-yi
        path=""
        :chartData="chart_data1"
        :jobList="jobList"
        @handleClickFangYiCount="handleClickMianYi"
        @selectJob="selectJob"
      />
    </div>
    
     <!-- 动物免疫明细 -->
    <div class="w_full h_49_p p_10 bgs_full chart_box_bgi_1 relative">
      <animal-mian-yi 
        path=""
        :chartData="chart_data2"
        :animalId="animalId"
        @handleClickAnimalName="handleClickAnimalName"
      />
    </div> 
  </div>
</template>

<script>
  import defMixin from '@/mixins/def';
  import componentsMixin from '@/mixins/components.js'

  import AnimalCunLanFangYi from '@/components/data-charts/animalCunLanFangYi.vue';
  import AnimalMianYi from '@/components/data-charts/animalMianYi.vue';
  import { getStatisticByUserId, getRecordsYiStatistics, filterRecordsYiCount } from '@/api/fang-yi/fang-yi.js'

  export default {
    name: 'MuHuMianYiYiMiaoChart',
    components: { AnimalCunLanFangYi, AnimalMianYi },
    mixins: [defMixin, componentsMixin],
    props: {
      jobId: null,

      countyId: '',
      townId: '',
      villageId: '',
      groupId: '',
      userId: '',

      path: ''
    },
    data() {
      return {
        active_animal_id: null,

        jobId: null,

        chart_1_activeYear: '2023',
        chart_1_yimiao_id: null,
        chart_1_activeSeason: '',

        yiMiaoTypeList: [],

        chart_data1: {
          unit: '',
          x: [],
          data1: {
            name: '存栏',
            data: []
          },
          data2: {
            name: '防疫',
            data: []
          },
          timeYearList: [],
          animalList: []
        },

        animalId: null,
        animalName: '牛',

        yiMiaoList: [],

        chart_data2: {
          unit: '头、只、羽',
          x: [],
          y: [],
          vaccinumList: [],
        },

      };
    },
    watch: {
      async jobId(val) {
        // 获取 牧户的 存栏 免疫 统计
        await this.getStatisticByUserId(this.userId, val)

        // 获取 所有免疫记录
        await this.getRecordsYiStatistics(this.animalId, val)
      }
    },
    created() {
      
    },
    async mounted() {

    },
    methods: {
      // 获取 牧户的 存栏 免疫 统计
      async getStatisticByUserId(userId, jobId) {
        let res = await getStatisticByUserId(userId, jobId)
        try {
          if (res.code == 1000) {
            let cattleList = []
            let animalCountList = []
            let fangYiList = []
            let animalList = []

            let animalCount = 0
            let fangYiCount = 0

            if (res.data && res.data.length) {
              let list = this.formatAnimalList(res.data)
              for(let i in list) {
                animalList.push({ id: list[i].animalId, name: list[i].animalName})
                cattleList.push(list[i].animalName)
                animalCountList.push(list[i].animalCount)
                fangYiList.push(list[i].fangYiCount)
              }

              animalList = this.formatList(animalList);
              this.chart_data2.animalList = animalList

              this.animalId = animalList[0].id

              this.chart_data1.x = Array.from(new Set(cattleList)).reverse()
              this.chart_data1.data1.data = animalCountList.reverse()
              this.chart_data1.data2.data = fangYiList.reverse()
              this.chart_data1.animalList = animalList

              for(let i in animalCountList) {
                animalCount += parseInt(animalCountList[i])
              }
              for(let i in fangYiList) {
                fangYiCount += parseInt(fangYiList[i])
              }
            } else {
              this.chart_data1.x = []
              this.chart_data1.data1.data = []
              this.chart_data1.data2.data = []
              this.chart_data1.animalList = []
            }
            this.chart_data1 = {...this.chart_data1}
            this.$emit('changeCount', {animalCount, fangYiCount})
          }
        } catch(err) {
          console.log(err)
        }
      },

      // 获取 动物 的疫苗使用量
      async getRecordsYiStatistics(animalId, jobId) {
        let params = {
          countyId: this.countyId || '',
          townId: this.townId || '',
          villageId: this.villageId || '',
          // groupId: this.groupId || '',
          userId: parseInt(this.userId) || '',
          animalId,
          jobId
        }
        let res = await getRecordsYiStatistics(params)
        // debugger
        try {
          if (res.code == 1000) {
            let vaccinumList = []
            let vaccinumNameList = []
            let vaccinumValueList = []

            for (let i in res.data) {
              let item = { 
                id: res.data[i].vaccinumId, 
                name: res.data[i].vaccinumName
              }

              vaccinumList.push(item)
              vaccinumNameList.push(res.data[i].vaccinumName + '免疫')
              vaccinumValueList.push(res.data[i].totalCount)
            }

            this.chart_data2.x = vaccinumNameList
            this.chart_data2.y = vaccinumValueList
            this.chart_data2.vaccinumList = vaccinumList

            this.chart_data2 = {...this.chart_data2}
          }
        } catch (err) {
          console.log(err)
        }
      },

      // 监听点击 免疫柱状图
      handleClickMianYi(animalId) {
        let seasonId = this.chart_1_yimiao_id
        let date = this.chart_1_activeYear

        this.active_animal_id = animalId

        // 更新 免疫数量 图表
        this.getRecordsYiStatistics(animalId, this.jobId)
      },

      // 获取 动物的 存栏、防疫统计
      async selectJob(jobId) {
        this.jobId = jobId

        await this.getStatisticByUserId(this.userId, jobId)

        await this.getRecordsYiStatistics(this.animalId, jobId)
      },

      // 点击 疫苗
      handleClickYiMiao(val) {
        this.receiveYiMiaoParams(val.id, val.name, this.chart_activeSeason)
      },

      // 选择 动物
      handleClickAnimalName(animalId) {
        this.getRecordsYiStatistics(animalId, this.jobId)
      },

      // 对象数组 去重
      formatList(tempArr) {
        let result = [];
          let obj = {};
          for (let i = 0; i < tempArr.length; i++) {
            if (!obj[tempArr[i].id]) {
                result.push(tempArr[i]);
                obj[tempArr[i].id] = true;
            };
        };

        return result;
      },

      // 格式化 牲畜 顺序 
      formatAnimalList(arr) {
        let sortdict = {
          "牛": 1,
          "羊": 2,
          "猪": 3,
          "禽": 4,
          "犬": 5,
          "马": 6,
          "驴": 7,
          "骆驼": 8
        }
        return arr.sort((a, b) => sortdict[a.animalName] - sortdict[b.animalName])
      },
    }
  }
</script>