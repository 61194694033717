<template>
  <div class="full relative">
    <!-- 动物 分类 -->
    <div 
      class="w_90_p m_0_auto flex_row_around color_ccc absolute top_7_p left_center z_999 x_scroll ph_10" 
      ref="tabName"
      @wheel="wheelScroll"
    >
      <div 
        class="item_name hover_main_color" 
        :class="{'jian_bian_text_1 fw_bold border_b_3_s_5e93d4': active_animal_id == item.id}" 
        v-for="(item, index) in chartData.animalList" :key="index"
        @click="selectAnimal(item.id)"
      >
        {{ item.name }}
      </div>
    </div>

    <echart 
      title="动物免疫明细" 
      chartType="柱状图3" 
      :isHideFullIcon="false"
      :chartData="chartData" 
      @emitChartParams="handleClickYiMiao" 
    />
  </div>
</template>

<script>
  import defMixin from '@/mixins/def';
  import componentsMixin from '@/mixins/components.js'

  export default {
    name: 'AnimalMianYi',
    mixins: [defMixin, componentsMixin],
    props: {
      chartData: {},
      animalId: '',
      animalList: [],

      path: '',
    },
    data() {
      return {
        active_animal_id: null,
        animalName: '牛'
      };
    },

    watch: {
      animalId(newVal) {
        // this.animalId = newVal

        this.active_animal_id = newVal
      }
    },
    
    async mounted() {
    
    },
    methods: {
      // 点击 柱状图 x , 疫苗线条, 跳转到 疫苗详情页
      handleClickYiMiao(val) {
        // this.goToVacciumnDetail(val.id, val.name)
      },

      // 选择 动物
      selectAnimal(animalId) {        
        this.active_animal_id = animalId

        this.$emit('handleClickAnimalName', animalId)
      } 
    }
  }
</script>
