<template>
  <div class="full flex_column_between chart_box_bgi_2">
    <!-- 动物存栏免疫统计 -->
    <div class="w_full h_50_p p_10 bgs_full relative">      
      <animal-cun-lan-fang-yi
        path="/mianYiTaiZhang/null"
        :chartData="chart_data1"
        @handleClickFangYiCount="handleClickFangYiCount"
        @selectJob="selectJob"
      />
    </div>

    <!-- 分割线 -->
    <div class="w_80_p h_2 m_0_auto border_b_1_s_main_color"></div>

    <!-- 动物免疫明细 -->
    <div class="w_full h_50_p p_10 bgs_full relative">
      <animal-mian-yi 
        :chartData="chart_data2"
        :animalId="animalId"
        @handleClickAnimalName="handleClickAnimalName"
      />
    </div>
  </div>
</template>

<script>
  import defMixin from '@/mixins/def';
  import componentsMixin from '@/mixins/components.js'

  import AnimalCunLanFangYi from '@/components/data-charts/animalCunLanFangYi.vue';
  import AnimalMianYi from '@/components/data-charts/animalMianYi.vue';

  import { getMytzPage } from '@/api/fang-yi/fang-yi.js'

  export default {
    name: 'CunLanMianYi',
    components: { AnimalCunLanFangYi, AnimalMianYi },
    mixins: [defMixin, componentsMixin],
    props: {
      areaCode: '',
      parentAreaCode: '',
      parentLevel: '',
      jobId: null,
    },
    data() {
      return {
        animalId: null,

        cunLanFangYiData: [],

        chart_data1: {
          unit: '',
          x: [],
          data1: {
            name: '存栏数',
            data: []
          },
          data2: {
            name: '防疫数',
            data: []
          },
          animalList: [],
        },

        chart_data2: {
          unit: '头、只、羽',
          x: [],
          y: [],
          vaccinumList: [],
        },
      };
    },
    watch: {
      async jobId(val) {
        // 获取 存栏免疫 统计
        let params1 = {
          areaCode: this.areaCode,
          parentAreaCode: this.parentAreaCode,
          parentLevel: this.parentLevel - 1,
          jobId: val,
          mergeVaccin: 1,
          page: 1,
          size: 10
        }
        await this.getAreaCunLanFangYi(params1)



        // 获取 动物 疫苗 使用量 （免疫明细）
        let params2 = {
          animalId: this.animalId,
          areaCode: this.areaCode,
          parentAreaCode: this.parentAreaCode,
          parentLevel: this.parentLevel - 1,
          jobId: val,
          mergeVaccin: 0,
          page: 1,
          size: 10
        }
        await this.getMianYiMingXi(params2)

      }
    },
    mounted() {
      
    },
    methods: {
      // 获取 存栏 防疫 数量
      async getAreaCunLanFangYi(params) {
        let res = await getMytzPage(params)
        // debugger
        try {
          if (res.code == 1000) {
            let cattleList = []
            let animalList = []
            let animalCountList = []
            let fangYiList = []

            let animalCount = 0
            let fangYiCount = 0

            if(res.data.list && res.data.list.length) {
              this.cunLanFangYiData = res.data.list
              let sortList = this.formatAnimalList(res.data.list)

              sortList.map(item => {
                cattleList.push(item.animalName)
                animalList.push({ id: item.animalId, name: item.animalName })
                animalCountList.push(item.animalCount)
                fangYiList.push(item.fangYiCount)
              })

              this.animalId = animalList[0].id

              this.chart_data2.animalList = animalList

              this.chart_data1.x = cattleList.reverse()
              this.chart_data1.data1.data = animalCountList.reverse()
              this.chart_data1.data2.data = fangYiList.reverse()
              this.chart_data1.animalList = animalList

              for(let i in animalCountList) {
                animalCount += parseInt(animalCountList[i])
              }
              for(let i in fangYiList) {
                fangYiCount += parseInt(fangYiList[i])
              }
            } else {
              this.chart_data1.x = []
              this.chart_data1.data1.data = []
              this.chart_data1.data2.data = []
              this.chart_data1.animalList = []
            }

            this.chart_data1 = {...this.chart_data1}
            this.$emit('changeCount', {animalCount, fangYiCount})
          }
        } catch (err) {
          console.log(err)
        }
      },

      // 获取 牲畜的 疫苗使用量
      async getMianYiMingXi(params) {
        let res = await getMytzPage(params)
        // debugger
        try {
          if (res.code == 1000) {
            let vaccinumList = []
            let vaccinumNameList = []
            let vaccinumValueList = []

            for (let i in res.data.list) {
              let item = { 
                // id: res.data.list[i].vaccinumId, 
                name: res.data.list[i].vaccinumName
              }

              vaccinumList.push(item)
              vaccinumNameList.push(res.data.list[i].vaccinumName + '免疫')
              vaccinumValueList.push(res.data.list[i].fangYiCount)
            }

            this.chart_data2.x = vaccinumNameList
            this.chart_data2.y = vaccinumValueList

            this.chart_data2 = {...this.chart_data2}
          }
        } catch (err) {
          console.log(err)
        }
      },

      // 选择工作任务, 更新存栏免疫统计
      async selectJob(jobId) {
        // debugger
        this.jobId = jobId

        // 重新 获取 存栏免疫数据
        let params1 = {
          areaCode: this.areaCode,
          parentAreaCode: this.parentAreaCode,
          parentLevel: this.parentLevel - 1,
          jobId,
          mergeVaccin: 1,
          page: 1,
          size: 10
        }
        await this.getAreaCunLanFangYi(params1)


        // 获取 动物 疫苗 使用量 （免疫明细）
        let params2 = {
          animalId: this.animalId,
          areaCode: this.areaCode,
          parentAreaCode: this.parentAreaCode,
          parentLevel: this.parentLevel - 1,
          jobId,
          mergeVaccin: 0,
          page: 1,
          size: 10
        }
        await this.getMianYiMingXi(params2)
      },

      // 选择 动物
      handleClickAnimalName(animalId) {
        // 获取 动物 疫苗 使用量 （免疫明细）
        let params = {
          animalId: animalId,
          areaCode: this.areaCode,
          parentAreaCode: this.parentAreaCode,
          parentLevel: this.parentLevel - 1,
          jobId: this.jobId,
          mergeVaccin: 0,
          page: 1,
          size: 10
        }
        
        this.getMianYiMingXi(params)
      },

      // 格式化 牲畜 顺序 
      formatAnimalList(arr) {
        let sortdict = {
          "牛": 1,
          "羊": 2,
          "猪": 3,
          "禽": 4,
          "犬": 5,
          "马": 6,
          "驴": 7,
          "骆驼": 8
        }
        return arr.sort((a, b) => sortdict[a.animalName] - sortdict[b.animalName])
      },

      // 监听点击 双柱图 防疫 线条
      handleClickFangYiCount(animalId) {
        this.animalId = animalId

        let params = {
          animalId: animalId,
          areaCode: this.areaCode,
          parentAreaCode: this.parentAreaCode,
          parentLevel: this.parentLevel - 1,
          jobId: this.jobId,
          mergeVaccin: 0,
          page: 1,
          size: 10
        }
        
        this.getMianYiMingXi(params)
      }



    }
  }
</script>
