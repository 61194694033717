<template>
  <div class="full relative ph_5">
    <!-- 筛选 -->
    <!-- <div class="w_50_p flex_row_end absolute top_0 right_30 z_999"> 
      <div class="w_25 h_25">
        <img class="full cursor" src="~@/assets/images/chart-box/filter.png" alt="" @click="showFilterBox = true">
      </div>
    </div> -->

    <echart 
      :title="title" 
      :path="path" 
      chartType="环状图" 
      :isShowUnit="isShowUnit"
      :isHideFullIcon="isHideFullIcon"
      :chartData="chartData" 
      @emitChartParams="handleClickYiMiao" 
    />

    <!-- 筛选区 -->
    <div 
      class="full absolute top_0 right_0" 
      v-show="showFilterBox"
      @click="showFilterBox = false"
    >
      <div class="w_40_p h_full absolute top_0 right_0 p_10 bgc_001531 z_999">
        <div class="w_full flex_row_between">
          <div class="w_85_p">
            <el-select 
              class="w_full_i" 
              v-model="jobId" 
              size="mini" 
              placeholder="防疫工作" 
              clearable filterable
            >
              <el-option
                v-for="(item, index) in jobList" :key="index"
                :label="item.name"
                :value="item.id"
                @click.native="selectJob(item.id)"
              >
              </el-option>
            </el-select>
          </div>

          <div class="w_15_p relative color_fff fw_bold cursor" @click="showFilterBox = false">
            <div class="w_20 h_20 absolute top_0 right_0">
              <img class="full cursor" src="~@/assets/images/chart-box/cancel.png" alt="">
            </div>
          </div>
        </div>
        
      </div>
    </div>
  </div>
</template>

<script>
  import defMixin from '@/mixins/def';
  import componentsMixin from '@/mixins/components.js'
  import { getJobList } from '@/api/fang-yi/fang-yi.js'

  export default {
    name: 'YiMiaoChart',
    mixins: [defMixin, componentsMixin],
    components: {  },
    props: {
      title: '',
      chartData: {},
      isHideFullIcon: false,
      isShowUnit: true,
      path: ''
    },
    data() {
      return {
        showFilterBox: false,
        vaccinumTypeId: null,

        animalList: ['牛', '羊', '猪', '禽', '犬', '马', '驴'],

        activeIndex: 0,
        chart_activeYear: null,
        chart_activeSeason: '1',      // 春季： spring， 秋季： autumn

        yiMiaoList: [],

        jobId: null,
        jobList: []
      };
    },
    created() {

    },
    mounted() {
      // this.getJobList()
    },
    methods: {
      // 获取 防疫工作 列表
      async getJobList() {
        let res = await getJobList()
        try {
          if (res.code == 1000) {
            this.jobList = res.data.filter(item => item.isType == 1)
            let jobId = null
            
            for (let i in res.data) {
              if (res.data[i].active == 1) {
                jobId = res.data[i].id
                this.jobId = jobId

                this.$emit('selectJob', jobId)
              }
            }
          }
        } catch(err) {
          console.log(err)
        }
      },

      // 点击 疫苗
      handleClickYiMiao(val) {
        val.chart_activeSeason = this.chart_activeSeason
        this.$emit('clickYiMiao', val)
      },

      // 点击 动物名称
      handleClickAnimal() {

      },

      // 选择工作任务
      selectJob(jobId) {
        this.jobId = jobId

        this.showFilterBox = false
    
        // 向 父组件 发送
        this.$emit('selectJob', jobId)
      },
    }
  }
</script>